import React from "react";
import POScomponent from "../Components/common/POS";
import {
  getPos,
  getPosPages,
  createPOS,
  getClients,
  getCompanies,
  getSuppliers,
  getPosByID,
} from "../API/Cheques&Pos";

import FloatingFormButton from "../../Components/common/FloatingFormButton";
import UniversalTable from "../../Components/common/UniversalTable";

import FilterArea from "../../Components/common/FilterArea";

import { Outlet, Link } from "react-router-dom";

import { IconButton, Tooltip, Box, Typography } from "@mui/material";

import { dateRange, convertToBool } from "../Components/common/FilterUtility";

import {
  LoadingProvider,
  LoadingContext,
} from "../Components/common/LoadingProvider";

import ConfirmationErrors from "../Components/common/ConfirmationErrors";
import {
  ListAltOutlined,
  MoreVert,
  Payment,
  WarningAmberRounded,
} from "@mui/icons-material";
import CopyText from "../Components/common/CopyTextPOS";
import POSReports from "../Components/common/POSReports";

export default function Main() {
  // advanced search
  const [currency, setCurrency] = React.useState(""),
    [supplierName, setSupplierName] = React.useState(""),
    [company, setCompany] = React.useState(""),
    [client, setClient] = React.useState(""),
    [status, setStatus] = React.useState(""),
    [confirmation, setConfirmation] = React.useState(""),
    [orderID, setOrderID] = React.useState(""),
    [exempt, setExempt] = React.useState(""),
    [date, setDate] = React.useState("");

  //filter function
  const getFilterData = (filter) => {
    const from = new Date(date[1]);
    const to = new Date(date[2]);

    const data = filter
      ? {
          currency,
          supplierName,
          company,
          client,
          status: convertToBool(status, "Accepted"),
          confirmation,
          orderID,
          from:
            date[0] === "less" || date[0] === ""
              ? null
              : from.toLocaleString("en-GB", { timeZone: "UTC" }),
          to:
            date[0] === "greater" || date[0] === ""
              ? null
              : to.toLocaleString("en-GB", { timeZone: "UTC" }),
          currentPage: 1,
        }
      : {
          company: null,
          client: null,
          confirmation: null,
          orderID: null,
          currency: null,
          supplierName: null,
          status: null,
          from: null,
          to: null,
          currentPage: 1,
        };
    return data;
  };
  return (
    <>
      <LoadingProvider
        getFilterData={getFilterData}
        getData={getPos}
        getPages={getPosPages}
      >
        <POSTable
          setCurrency={setCurrency}
          currency={currency}
          setSupplierName={setSupplierName}
          supplierName={supplierName}
          setStatus={setStatus}
          status={status}
          setExempt={setExempt}
          exempt={exempt}
          setDate={setDate}
          date={date}
          setCompany={setCompany}
          company={company}
          setClient={setClient}
          client={client}
          setConfirmation={setConfirmation}
          confirmation={confirmation}
          setOrderID={setOrderID}
          orderID={orderID}
        />
      </LoadingProvider>
      <ConfirmationErrors />
      <POSReports />
    </>
  );
}

function POSTable({
  setCurrency,
  currency,
  setSupplierName,
  supplierName,
  setStatus,
  status,
  setDate,
  date,
  setCompany,
  company,
  setClient,
  client,
  setConfirmation,
  confirmation,
  setOrderID,
  orderID,
}) {
  // Table components
  function Settings({ values }) {
    return (
      <Tooltip title="Manage POS">
        <IconButton
          aria-label="settings"
          component={Link}
          to={`edit?pos=${values.id}`}
          sx={{ float: "right" }}
        >
          <MoreVert />
        </IconButton>
      </Tooltip>
    );
  }
  // Table components
  const items = [
    {
      id: "orderItem",
      label: "Order ID",
    },
    {
      id: "partnumber",
      label: "Part Number",
    },
    {
      id: "description",
      label: "Description",
    },
    {
      id: "quantity",
      label: "Quantity",
    },
    {
      id: "cost",
      label: "Cost",
    },
  ];
  const cheques = [
    {
      id: "number",
      label: "Number",
    },
    {
      id: "bank",
      label: "Bank",
    },
    {
      id: "dateWritten",
      label: "Date Written",
      date: true,
    },
    {
      id: "dateSigned",
      label: "Date Signed",
      date: true,
    },
    {
      id: "amount",
      label: "Amount",
    },
    {
      id: "status",
      label: "Status",
    },
  ];

  // Table components

  const headers = [
    {
      id: "items",
      subRow: true,
      label: "",
      iconColor: "success",
      headers: items,
      subTitle: "Items",
      openIcon: <ListAltOutlined />,
    },
    {
      id: "cheques",
      subRow: true,
      label: "",
      iconColor: "info",
      headers: cheques,
      subTitle: "Cheques",
      openIcon: <Payment />,
    },
    {
      id: "id",
      label: "PO Number",
      searchable: true,
    },
    {
      id: "supplier",
      label: "Supplier",
      searchable: true,
    },
    {
      id: "date_created",
      label: "Date Created",
      searchable: true,
      date: true,
    },
    {
      id: "amount",
      label: "Amount",
      searchable: true,
    },
    {
      id: "unapproved",
      label: "",
      sortParam: "unapproved",
      component: (value) =>
        value.unapproved ? (
          <Tooltip title="One or more orders are not approved">
            <Box
              color={(theme) => theme.palette.warning.dark}
              sx={{
                bgcolor: "warning.main",
                px: 0.5,
                py: 0.1,
                width: "fit-content",
                borderRadius: "50%",
              }}
            >
              <WarningAmberRounded color="inherit" />
            </Box>
          </Tooltip>
        ) : null,
    },
    {
      id: "settings",
      label: "",
      component: (values) => <Settings values={values} />,
    },
  ];
  //context data
  const {
    loadingIndicator,
    currentPage,
    totalPages,
    loading,
    data,
    gotAll,
    allData,
    setData,
    loadFiltered,
  } = React.useContext(LoadingContext);

  //check if it should go straight to the create pos form
  const [open, setOpen] = React.useState(
    JSON.parse(localStorage.getItem("create_pos")) || false
  );

  const [clear, setClear] = React.useState(false);

  // advanced search functions

  const handleAdvancedSearch = () => {
    const clientSearch = (item) => {
      return (
        [item.client.toLowerCase(), ""].includes(client.toLowerCase()) &&
        [item.company.toLowerCase(), ""].includes(company.toLowerCase()) &&
        [item.order.toLowerCase(), ""].includes(orderID.toLowerCase()) &&
        [...item.confirmation, ""].includes(confirmation.toLowerCase())
      );
    };

    if (gotAll) {
      const filtered = allData.filter(
        (data) =>
          [data.supplier.toLowerCase(), ""].includes(
            supplierName.toLowerCase()
          ) &&
          [data.accepted, null].includes(convertToBool(status, "Accepted")) &&
          data.currency.includes(currency) &&
          data.clients_list.find(clientSearch) &&
          dateRange(date[1], date[2], data.date_created)
      );
      setData(filtered);
      return;
    }
    loadFiltered();
  };

  const clearAdvancedSearch = () => {
    setSupplierName("");
    setCurrency("");
    setStatus("");
    setDate(["", null, null]);
    setClient("");
    setCompany("");
    setConfirmation("");
    setOrderID("");
    setClear(true);
  };
  React.useEffect(() => {
    localStorage.setItem("create_pos", JSON.stringify(open));
    if (!open) {
      localStorage.removeItem("poData");
    }
  }, [open]);

  const [copy, setCopy] = React.useState(false);
  const [copyData, setCopyData] = React.useState(null);
  const [posID, setPosID] = React.useState(null);

  const handleCopy = (id) => {
    setPosID(id);
    getPosByID(id).then((res) => {
      setCopyData({
        poItems: res.orderIDs.map((order) => ({
          orderID: order,
          items: res.edit_poitems.filter((item) => item.orderID === order),
        })),
        originalItems: res.orderIDs.map((order) => ({
          orderID: order,
          items: res.order_items.filter((item) => item.orderID === order),
        })),
        pendingItems: res.orderIDs.map((order) => ({
          orderID: order,
          items: res.pending_items.filter((item) => item.orderID === order),
        })),
        currency: res.currency,
        order_list: res.orders_list,
        cheques: res.cheques,
        poNumber: id,
        orderIDs: res.orderIDs,
        supplier: res.supplierID,
        defaultSupplier: res.defaultSupplier,
        amount: res.numberAmount,
        note: res.note,
        date: res.date_created,
        withholding: res.withholding,
        status: res.status,
      });
    });
  };

  React.useEffect(() => {
    if (copyData && !copy) setCopy(true);
  }, [copyData, copy]);

  const handleCopyClose = () => {
    setCopyData(null);
    setPosID(null);
    setCopy(false);
  };

  return (
    <>
      <Typography variant="h4" color="primary" gutterBottom>
        Purchase Orders
      </Typography>

      <FloatingFormButton
        title="Create POS"
        open={open}
        setOpen={setOpen}
        DialogFullScreen
      >
        <POScomponent
          title="Create POS"
          onSubmit={createPOS}
          onComplete={(id = null) => {
            setOpen(false);
            localStorage.removeItem("create_pos");

            if (id) {
              handleCopy(id);
            }

            loadFiltered();
          }}
        />
      </FloatingFormButton>

      {copyData && (
        <CopyText
          open={copy}
          handleClose={handleCopyClose}
          data={copyData}
          posID={posID}
          autoCopy
        />
      )}

      <Box>
        <FilterArea
          categories={[
            {
              label: "Supplier",
              type: "combo",
              value: supplierName,
              setValue: setSupplierName,
              getData: getSuppliers,
              optionLabel: "name",
              valueLabel: "name",
              clear: clear,
              setClear: setClear,
            },
            {
              label: "Currency",
              type: "select",
              options: ["KES", "USD"],
              value: currency,
              setValue: setCurrency,
            },
            {
              label: "POS Status",
              type: "select",
              options: ["Accepted", "Pending"],
              value: status,
              setValue: setStatus,
            },
            {
              label: "Company",
              type: "combo",
              value: company,
              setValue: setCompany,
              getData: getCompanies,
              optionLabel: "company",
              valueLabel: "company",
              clear: clear,
              setClear: setClear,
            },
            {
              label: "Client",
              type: "combo",
              value: client,
              setValue: setClient,
              getData: getClients,
              optionLabel: "name",
              valueLabel: "email",
              clear: clear,
              setClear: setClear,
            },
            {
              label: "Confirmations",
              type: "search",
              value: confirmation,
              setValue: setConfirmation,
            },
            {
              label: "Order ID",
              type: "search",
              value: orderID,
              setValue: setOrderID,
            },
            {
              label: "Date Created",
              type: "date",

              options: [],
              value: date,
              setValue: setDate,
            },
          ]}
          startFilter={handleAdvancedSearch}
          clearFilter={clearAdvancedSearch}
          isLoading={loading}
        />
      </Box>

      <UniversalTable
        headers={headers}
        loading={loading}
        lazyloading={loadingIndicator}
        currentPage={currentPage}
        totalPages={totalPages}
        setLoading={loadFiltered}
        data={data}
        name={"Purchase Orders"}
      />

      <Outlet context={{ loadFiltered, origin: "/Pos" }} />
    </>
  );
}
