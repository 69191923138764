import React from "react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  DialogActions,
  Stack,
  Typography,
  Divider,
  ListItem,
  ListItemText,
  Chip,
  ListItemIcon,
  CardActionArea,
  Tooltip,
  IconButton,
  Dialog,
  Paper,
  Skeleton,
  DialogTitle,
  DialogContent,
  List,
  Link,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Collapse,
  Alert,
  InputBase,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

import { getEditData, editReceived, editPOS } from "../../API/Cheques&Pos";

import { toast } from "react-toastify";
import EditPosCheques from "./EditPosCheques";
import POS from "../common/POS";

import { useNavigate, useLocation, useOutletContext } from "react-router-dom";
import AreYouSure from "../../../Components/common/AreYouSure";
import {
  ArrowBack,
  ArrowForward,
  Close,
  CopyAll,
  CreditCardOff,
  History,
  Payment,
  Search,
} from "@mui/icons-material";
import NumberFormat from "../../../Components/common/NumberFormater";
import CopyText from "../common/CopyTextPOS";

export default function AddItemForm() {
  const [items, setItems] = React.useState([]);

  const [editData, setEditData] = React.useState({});

  const [loadingEdit, setLoadingEdit] = React.useState(true);

  const [edited, setEdited] = React.useState(false);
  const [changed, setChanged] = React.useState(false);

  const [openPopUp, setOpenPopUp] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);

  const { loadFiltered, origin } = useOutletContext();
  const history = useNavigate();

  const urlDets = new URLSearchParams(useLocation().search);
  const posID = urlDets.get("pos");
  const [notFound, setNotFound] = React.useState(false);

  const goBack = () => {
    if (edited) loadFiltered();
    history(origin);
  };

  React.useEffect(() => {
    const handleGetEditData = () => {
      getEditData({
        id: posID,
      })
        .then((res) => {
          if (res.status === "error") {
            setNotFound(true);
            return;
          }

          setEditData((previous) => {
            previous.poItems = res.orderIDs.map((order) => ({
              orderID: order,
              items: res.edit_poitems.filter((item) => item.orderID === order),
            }));
            previous.originalItems = res.orderIDs.map((order) => ({
              orderID: order,
              items: res.order_items.filter((item) => item.orderID === order),
            }));
            previous.pendingItems = res.orderIDs.map((order) => ({
              orderID: order,
              items: res.pending_items.filter((item) => item.orderID === order),
            }));
            previous.currency = res.currency;
            previous.order_list = res.orders_list;
            previous.cheques = res.cheques;
            previous.poNumber = posID;
            previous.orderIDs = res.orderIDs;
            previous.supplier = res.supplierID;
            previous.defaultSupplier = res.defaultSupplier;
            previous.amount = res.numberAmount;
            previous.note = res.note;
            previous.date = res.date_created;
            previous.withholding = res.withholding;
            previous.status = res.status;
            return { ...previous };
          });
          setItems(res.edit_poitems.map((item) => ({ ...item })));
          setLoadingEdit(false);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    if (loadingEdit) {
      handleGetEditData();
    }
  }, [loadingEdit, posID]);

  const editReceiveQuantity = (value, id, setEdit) => {
    editReceived({ id, quantity: value })
      .then((res) => {
        setItems((previous) => {
          const item = previous.find((item) => item.po_item_id === id);
          item.received = value;
          return [...previous];
        });
        setEdit(false);
        toast.success(res);
      })
      .catch((err) => {
        console.log(err);
        toast.error("something went wrong editing the receive quantity");
      });
  };

  const padWithLeadingZeros = (num) => {
    return String(num).padStart(6, "0");
  };

  const [copyOpen, setCopyOpen] = React.useState(false);

  const [historyOpen, setHistoryOpen] = React.useState(false);

  const EditHistory = () => {
    return (
      <Dialog open={historyOpen} onClose={() => setHistoryOpen(false)}>
        <Paper elevation={0}>
          <Table>
            <TableBody>
              {editData?.status.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{new Date(item.date).toDateString()}</TableCell>
                  <TableCell>
                    {new Date(item.date).toLocaleTimeString()}
                  </TableCell>
                  <TableCell>
                    {item.status} by {item.user}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      </Dialog>
    );
  };

  return (
    <>
      <AreYouSure
        open={openPopUp}
        onDecline={() => setOpenPopUp(false)}
        onAccept={() => {
          setOpenEdit(false);
          setOpenPopUp(false);
        }}
        text={"Any changes made will be lost"}
      />

      <Dialog
        open={openEdit}
        onClose={() => {
          setOpenEdit(false);
        }}
        PaperProps={{ elevation: 0 }}
        fullScreen
      >
        <IconButton
          onClick={() => {
            if (changed) {
              setOpenPopUp(true);
              return;
            }
            setOpenEdit(false);
          }}
          sx={{ position: "absolute", top: 0, right: 0 }}
        >
          <Close />
        </IconButton>

        <POS
          title="Edit POS"
          edit={true}
          onSubmit={editPOS}
          data={editData}
          onComplete={() => {
            setEdited(true);
            setLoadingEdit(true);
          }}
          setChanged={setChanged}
          loadingEditData={loadingEdit}
        />

        <Paper
          sx={{
            p: 1,
            position: "sticky",
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 1301,
          }}
        >
          <DialogActions>
            <Button
              variant="outlined"
              color="warning"
              onClick={() => {
                if (changed) {
                  setOpenPopUp(true);
                  return;
                }

                setOpenEdit(false);
                setCopyOpen(true);
              }}
              startIcon={<CopyAll />}
              sx={{ height: "fit-content", ml: "auto" }}
            >
              Copy & Close
            </Button>

            <Button
              variant="outlined"
              color="warning"
              onClick={() => {
                if (changed) {
                  setOpenPopUp(true);
                  return;
                }
                setOpenEdit(false);
              }}
              startIcon={<CancelIcon />}
              sx={{ height: "fit-content", ml: "auto" }}
            >
              Close
            </Button>
          </DialogActions>
        </Paper>
      </Dialog>

      <Dialog open={true} onClose={goBack}>
        <DialogTitle>Edit POS {posID}</DialogTitle>
        <Box
          sx={{ py: 0.5, px: 2, display: "flex", justifyContent: "flex-end" }}
        >
          <DirectSearch defaultValue={posID} />
        </Box>
        <DialogContent
          dividers
          sx={{ p: 0, display: "flex", justifyContent: "space-between" }}
        >
          <Button
            startIcon={<ArrowBack />}
            color="info"
            component={Link}
            href={`/Pos/edit?pos=${parseInt(posID) - 1}`}
          >
            BACK
          </Button>

          <Button
            endIcon={<ArrowForward />}
            color="info"
            component={Link}
            href={`/Pos/edit?pos=${parseInt(posID) + 1}`}
          >
            NEXT
          </Button>
        </DialogContent>
        <Collapse in={notFound}>
          <Alert
            severity="error"
            sx={{ p: 2, borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
          >
            Purchase Order Not Found
          </Alert>
        </Collapse>
        {loadingEdit ? (
          <Collapse in={!notFound}>
            <Skeleton width={500} height={500} />
          </Collapse>
        ) : (
          <>
            <Tooltip title="Copy POS Data">
              <IconButton
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 8,
                }}
                color="info"
                onClick={() => setCopyOpen(true)}
              >
                <CopyAll />
              </IconButton>
            </Tooltip>
            <CopyText
              data={editData}
              open={copyOpen}
              handleClose={() => setCopyOpen(false)}
              posID={posID}
              autoCopy
            />

            <DialogContent dividers>
              <Typography variant="overline">
                {editData?.date && new Date(editData?.date).toDateString()}
              </Typography>
              <IconButton variant="info" onClick={() => setHistoryOpen(true)}>
                <History />
              </IconButton>
              <EditHistory />
              <Typography variant="overline" sx={{ display: "block" }}>
                {editData?.defaultSupplier.name}
              </Typography>
              <Paper variant="outlined" sx={{ my: 1 }}>
                {items.map((item, index) => (
                  <Box key={item.id}>
                    <ItemsArea
                      item={item}
                      orderList={editData?.orderIDs}
                      AllOrders={editData?.order_list}
                      onComplete={editReceiveQuantity}
                      last={items.length - 1 === index}
                    />
                  </Box>
                ))}
              </Paper>
              {editData?.cheques.length > 0 && (
                <>
                  <Divider />
                  <Paper variant="outlined" sx={{ mt: 1 }}>
                    <List>
                      {editData?.cheques.map((chq, index) => (
                        <ListItem key={`${new Date()}_${index}`}>
                          <ListItemIcon>
                            {chq.cancelled ? (
                              <CreditCardOff color="error" />
                            ) : (
                              <Payment color="info" />
                            )}
                          </ListItemIcon>
                          <ListItemText
                            sx={{
                              textDecoration: chq.cancelled
                                ? "line-through"
                                : "none",
                            }}
                          >
                            {padWithLeadingZeros(chq.number)}
                          </ListItemText>
                          <ListItemText
                            sx={{
                              textDecoration: chq.cancelled
                                ? "line-through"
                                : "none",
                            }}
                          >
                            {editData?.currency}{" "}
                            {typeof chq.amount === "number"
                              ? NumberFormat(chq.amount.toFixed(2))
                              : chq.amount}
                          </ListItemText>
                          <ListItemText>{chq.bank}</ListItemText>
                        </ListItem>
                      ))}
                    </List>
                  </Paper>
                </>
              )}
            </DialogContent>
            <DialogActions
              sx={{ justifyContent: { xs: "center", md: "flex-end" } }}
            >
              <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
                <EditPosCheques
                  loading={loadingEdit}
                  data={editData}
                  setData={setEditData}
                  setEdited={setEdited}
                  copyClose={() => setCopyOpen(true)}
                />
                <LoadingButton
                  loading={loadingEdit}
                  variant="contained"
                  onClick={() => setOpenEdit(true)}
                >
                  Edit Purchase Order
                </LoadingButton>
                <Button variant="outlined" color="warning" onClick={goBack}>
                  Cancel
                </Button>
              </Stack>
            </DialogActions>
          </>
        )}
      </Dialog>
    </>
  );
}

function ItemsArea({ item, orderList, AllOrders, last }) {
  return (
    <>
      <ListItem>
        <ListItemText
          primary={
            <>
              <Typography>
                {item.description}{" "}
                {item.isStore && <Chip label="Store" color="info" />}
              </Typography>

              <Typography variant="caption">
                {`QTY ${item.quantity}`}
              </Typography>
            </>
          }
        />
        <CardActionArea
          sx={{
            ml: 2,
            borderRadius: 8,
            width: "max-content",
            p: 0.5,
            fontSize: "0.8rem",
          }}
          component={Link}
          href={`/adminOrderManager/overview?i=${orderList.join("/")}`}
          target="_blank"
        >
          {
            AllOrders.find((order) => order.orderID === item.orderID)
              ?.company_name
          }
        </CardActionArea>
      </ListItem>
      {!last && <Divider />}
    </>
  );
}

function DirectSearch({ defaultValue }) {
  const [hover, setHover] = React.useState(false);
  const [active, setActive] = React.useState(false);
  const [search, setSearch] = React.useState(defaultValue || "");

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent the form from actually submitting

    if (search.trim()) {
      window.location.href = `/Pos/edit?pos=${search}`; // Redirect to the search URL
    }
  };

  const handleClose = () => {
    if (!active) setHover(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Paper
        elevation={0}
        sx={{
          borderRadius: hover ? "24px" : "50px", // changes when hover starts or ends
          display: "flex",
          alignItems: "center",
          transition: "all 0.5s ease-in-out", // smooth transition in both directions
          p: hover ? 1 : 0.5, // smooth padding transition
          width: "fit-content", // fit content to children
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={handleClose}
      >
        <InputBase
          placeholder="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          onFocus={(e) => {
            setActive(true);
            e.target.select();
          }}
          onBlur={() => {
            setActive(false);
            setHover(false);
          }}
          sx={{
            ml: 1,
            width: hover ? "100%" : 0, // animate width in both hover states
            transition: "width 0.5s ease-in-out", // smooth width change
            ...(hover ? {} : { margin: 0 }), // remove margin when not hovering
          }}
          autoFocus
        />

        <IconButton
          component={Link}
          href={`/Pos/edit?pos=${search}`}
          disabled={!search || search.trim() === ""}
          type="submit"
        >
          <Search />
        </IconButton>
      </Paper>
    </form>
  );
}
