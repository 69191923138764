import {
  alpha,
  Badge,
  Box,
  Button,
  CardActionArea,
  Chip,
  Collapse,
  Fade,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React from "react";
import {
  getWeeklyOrderReports,
  weeklyReportEmail,
} from "../../API/Cheques&Pos";
import DateTimePicker from "./DateTimePicker";
import { LoadingButton } from "@mui/lab";
import {
  Email,
  ExpandMore,
  Refresh,
  Send,
  Visibility,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import TagField from "./TagField";
import { toast } from "react-toastify";

export default function OrderReports({
  from = null,
  to = null,
  lowPrivledge = false,
}) {
  const getFirstDayOfWeek = (date) => {
    const firstDay = new Date(date);
    const dayOfWeek = firstDay.getDay();
    const diff = firstDay.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1);
    firstDay.setDate(diff);
    return firstDay;
  };

  const getLastDayOfWeek = (date) => {
    const lastDay = new Date(date);
    const dayOfWeek = lastDay.getDay();
    const diff = lastDay.getDate() + (6 - dayOfWeek);
    lastDay.setDate(diff);
    return lastDay;
  };

  const [date1, setDate1] = React.useState(
    from ? new Date(from) : getFirstDayOfWeek(new Date())
  );
  const [date2, setDate2] = React.useState(
    to ? new Date(to) : getLastDayOfWeek(new Date())
  );

  const [loading, setLoading] = React.useState(true);
  const [fetchDate, setFetchDate] = React.useState(new Date());
  const [dataAge, setDataAge] = React.useState("0 seconds ago");
  const [data, setData] = React.useState({});

  React.useEffect(() => {
    if (loading) {
      getWeeklyOrderReports({ from: date1, to: date2 })
        .then((res) => {
          setData(res);
          setFetchDate(new Date());
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [loading, date1, date2]);

  React.useEffect(() => {
    // each second update the data age
    const interval = setInterval(() => {
      // get the difference in seconds between the fetch date and now
      const diff = Math.floor((new Date() - fetchDate) / 1000);
      if (diff < 60) return setDataAge(`${diff} seconds ago`);
      if (diff < 3600)
        return setDataAge(`${Math.floor(diff / 60)} minutes ago`);
      setDataAge(`${Math.floor(diff / 3600)} hours ago`);
    }, 1000);
    return () => clearInterval(interval);
  }, [fetchDate]);

  const listStyle = {
    maxHeight: "500px",
    overflowY: "auto",
  };

  const completeRef = React.useRef(null);
  const pendingRef = React.useRef(null);
  const issueRef = React.useRef(null);
  const notApprovedRef = React.useRef(null);
  const b2bRef = React.useRef(null);
  const repairRef = React.useRef(null);

  const scrollToRef = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  const OverViewButton = ({ name, scrollRef, count, countColour }) => {
    return (
      <Grid item xs={12} md={6}>
        <CardActionArea onClick={() => scrollToRef(scrollRef)}>
          <Paper
            elevation={1}
            sx={{
              p: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography>{name}</Typography>
            <Chip color={countColour} label={count} />
          </Paper>
        </CardActionArea>
      </Grid>
    );
  };

  return (
    <Box sx={{ p: 2 }}>
      {!lowPrivledge && (
        <>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="flex-start"
            alignItems={"center"}
            sx={{ mb: 3 }}
          >
            <DateTimePicker
              label="From"
              value={date1}
              setChange={(date) => setDate1(date)}
            />
            <DateTimePicker
              label="To"
              value={date2}
              setChange={(date) => setDate2(date)}
            />
          </Stack>
          <ReportEmail to={date2} from={date1} />
        </>
      )}
      {lowPrivledge && (
        <Typography variant="overline" sx={{ display: "block" }}>
          {`From: ${date1.toDateString()} To: ${date2.toDateString()}`}
        </Typography>
      )}

      <LoadingButton
        startIcon={<Refresh />}
        onClick={() => setLoading(true)}
        loading={loading}
      >
        Refresh - Last Update {dataAge}
      </LoadingButton>

      <Collapse in={loading}>
        <Stack spacing={3} sx={{ mt: 2 }}>
          <Skeleton variant="rectangular" height={100} />
          <Skeleton variant="rectangular" height={100} />
          <Skeleton variant="rectangular" height={100} />
          <Skeleton variant="rectangular" height={100} />
        </Stack>
      </Collapse>
      <Collapse in={!loading}>
        <Stack spacing={2}>
          <Grid container spacing={2} sx={{ pr: 4, pb: 2 }}>
            <OverViewButton
              name="Completed"
              scrollRef={completeRef}
              count={data?.completed?.length}
              countColour="success"
            />
            <OverViewButton
              name="Pending Delivery"
              scrollRef={pendingRef}
              count={data?.pending?.length}
              countColour="error"
            />
            <OverViewButton
              name="Issues"
              scrollRef={issueRef}
              count={data?.issue?.length}
              countColour="error"
            />
            <OverViewButton
              name="Preparing for approval"
              scrollRef={notApprovedRef}
              count={data?.not_approved?.length}
              countColour="warning"
            />
            <OverViewButton
              name="B2B"
              scrollRef={b2bRef}
              count={data?.b2b?.length}
              countColour="info"
            />
            <OverViewButton
              name="Repairs"
              scrollRef={repairRef}
              count={data?.repair?.length}
              countColour="info"
            />
          </Grid>

          <LabelBox
            label="Completed Orders"
            count={data?.completed?.length}
            badgeColor="success"
            refPointer={completeRef}
          >
            <Typography variant="body2" color={"text.secondary"} sx={{ ml: 3 }}>
              Orders delivered fully or marked as completed
            </Typography>
            <List sx={listStyle}>
              {data?.completed?.map((item, index) => (
                <ItemDisplay
                  key={item.orderID}
                  data={item}
                  odd={index % 2 !== 0}
                />
              ))}
            </List>
          </LabelBox>
          <Typography>Pending Orders</Typography>

          <Stack spacing={3} sx={{ mt: 2 }}>
            <LabelBox
              label="Pending Delivery"
              count={data?.pending?.length}
              refPointer={pendingRef}
            >
              <Typography
                variant="body2"
                color={"text.secondary"}
                sx={{ ml: 3 }}
              >
                Orders that have been approved but not delivered
              </Typography>
              <List sx={listStyle}>
                {data?.pending?.map((item, index) => (
                  <ItemDisplay
                    key={item.orderID}
                    data={item}
                    odd={index % 2 !== 0}
                  />
                ))}
              </List>
            </LabelBox>
            <LabelBox
              label="Preparing for approval"
              count={data?.not_approved?.length}
              refPointer={notApprovedRef}
            >
              <Typography
                variant="body2"
                color={"text.secondary"}
                sx={{ ml: 3 }}
              >
                That have not been approved by admin yet
              </Typography>
              <List sx={listStyle}>
                {data?.not_approved?.map((item, index) => (
                  <ItemDisplay
                    key={item.orderID}
                    data={item}
                    odd={index % 2 !== 0}
                  />
                ))}
              </List>
            </LabelBox>
            <LabelBox
              label="Issues"
              count={data?.issue?.length}
              refPointer={issueRef}
            >
              <Typography
                variant="body2"
                color={"text.secondary"}
                sx={{ ml: 3 }}
              >
                Orders that have an issue
              </Typography>
              <List sx={listStyle}>
                {data?.issue?.map((item, index) => (
                  <ItemDisplay
                    key={item.orderID}
                    data={item}
                    odd={index % 2 !== 0}
                  />
                ))}
              </List>
            </LabelBox>
            <LabelBox
              label="B2B"
              count={data?.b2b?.length}
              badgeColor="warning"
              refPointer={b2bRef}
            >
              <Typography
                variant="body2"
                color={"text.secondary"}
                sx={{ ml: 3 }}
              >
                Orders that have been marked as B2B
              </Typography>
              <List sx={listStyle}>
                {data?.b2b?.map((item, index) => (
                  <ItemDisplay
                    key={item.orderID}
                    data={item}
                    odd={index % 2 !== 0}
                    showExtra
                  />
                ))}
              </List>
            </LabelBox>
            <LabelBox
              label="Repairs"
              count={data?.repair?.length}
              badgeColor="info"
              refPointer={repairRef}
            >
              <Typography
                variant="body2"
                color={"text.secondary"}
                sx={{ ml: 3 }}
              >
                Orders that have been marked as repairs
              </Typography>
              <List sx={listStyle}>
                {data?.repair?.map((item, index) => (
                  <ItemDisplay
                    key={item.orderID}
                    data={item}
                    odd={index % 2 !== 0}
                    showExtra
                  />
                ))}
              </List>
            </LabelBox>
          </Stack>
        </Stack>
      </Collapse>
    </Box>
  );
}

function ItemDisplay({ data, odd, showExtra = false }) {
  const [hover, setHover] = React.useState(false);

  const status = data.last_status;

  const chipLabel = data.last_status_note
    ? data.last_status_note.length > 20
      ? status + " | " + data.last_status_note.slice(0, 20) + "..."
      : status + " | " + data.last_status_note
    : status;

  let colour = "default";
  switch (status) {
    case "completed":
      colour = "success";
      break;
    case "all items ordered":
      colour = "success";
      break;
    case "issue":
      colour = "error";
      break;
    case "some items ordered":
      colour = "warning";
      break;
    case "partially delivered":
      colour = "warning";
      break;
    case "info":
      colour = "info";
      break;
    default:
      colour = "default";
  }

  return (
    <ListItem
      sx={{
        ...(odd && { bgcolor: "background.default" }),
        ...(data.age > 2 && {
          bgcolor: (theme) =>
            odd
              ? alpha(
                  theme.palette.error.main,
                  theme.palette.action.activatedOpacity
                )
              : alpha(
                  theme.palette.error.main,
                  theme.palette.action.disabledOpacity
                ),
        }),
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <ListItemText
        primary={
          <>
            <Box sx={{ display: "block" }}>{data.orderID}</Box>
            {data.client}{" "}
            <Tooltip title={data.last_status_note}>
              <Chip label={showExtra ? chipLabel : status} color={colour} />
            </Tooltip>
          </>
        }
        secondary={
          <>
            {new Date(data.date).toDateString()} | Days ({data.age})
          </>
        }
      />
      <Fade in={hover}>
        <Button
          startIcon={<Visibility />}
          color="inherit"
          component={Link}
          to={`/redirect?c=order&i=${data.orderID}`}
          target="_blank"
        >
          View
        </Button>
      </Fade>
    </ListItem>
  );
}

function LabelBox({
  label,
  count = 0,
  badgeColor = "primary",
  refPointer,
  children,
}) {
  const [open, setOpen] = React.useState(true);
  return (
    <Paper
      variant="outlined"
      ref={refPointer}
      sx={{ scrollMarginTop: "100px" }}
    >
      <Typography
        backgroundColor="background.paper"
        sx={{
          position: "relative",
          mt: -1.5,
          width: "fit-content",
          px: 1,
          ml: 2,
        }}
      >
        {label}
        <Badge badgeContent={count} color={badgeColor} sx={{ ml: 2 }} />
      </Typography>
      <CardActionArea
        onClick={() => setOpen(!open)}
        sx={{
          position: "relative",
          mt: -1.5,
          borderRadius: 0,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <ExpandMore
          sx={{
            transform: !open ? "rotate(0deg)" : "rotate(180deg)",
          }}
        />
      </CardActionArea>
      <Collapse in={!open} unmountOnExit>
        <Typography sx={{ pl: 1 }} variant="overline">
          Content Hidden
        </Typography>
      </Collapse>
      <Collapse in={open}>{children}</Collapse>
    </Paper>
  );
}

function ReportEmail({ to, from }) {
  const [open, setOpen] = React.useState(false);

  const [emails, setEmails] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const handleSendReportEmail = () => {
    setLoading(true);
    weeklyReportEmail({ to, from, emails })
      .then((res) => {
        toast.success("Email sent successfully");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Stack spacing={2} alignItems="flex-start" sx={{ my: 2 }}>
      <Button
        startIcon={<Email />}
        variant="contained"
        onClick={() => setOpen(!open)}
      >
        {open && "Hide"} Send Email
      </Button>
      <Collapse in={open} unmountOnExit>
        <Paper variant="outlined" sx={{ p: 2 }}>
          <Stack spacing={2} alignItems="flex-start">
            {false && (
              <TagField
                label={"Emails"}
                value={emails}
                setValue={setEmails}
                TextFieldProps={{
                  fullWidth: true,
                  helperText: "Please separate each email wihh a comma.",
                }}
                seperator=","
              />
            )}
            <LoadingButton
              startIcon={<Send />}
              loading={loading}
              onClick={handleSendReportEmail}
              variant="outlined"
            >
              Send Email {emails.length < 1 && "to POS and Logisitcs"}
            </LoadingButton>
          </Stack>
        </Paper>
      </Collapse>
    </Stack>
  );
}
