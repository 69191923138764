import { ContentCopy, CopyAll } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogContent,
  Paper,
  Stack,
  useTheme,
} from "@mui/material";
import { toast } from "react-toastify";
import config from "../../Config";

export default function CopyText({
  posID,
  data,
  open,
  handleClose,
  autoCopy = false,
}) {
  let clientList = "";
  let confirmationList = "";
  let itemsList = "";

  data?.order_list?.forEach((order, index) => {
    clientList += `${order.company_name}`;
    if (index !== data?.order_list.length - 1) clientList += " /";
    confirmationList += `${order.confirmation.details}`;
    if (index !== data?.order_list.length - 1) confirmationList += " /";
  });

  data?.poItems?.forEach((order) => {
    order.items.forEach((item) => {
      itemsList += `${item.partNumber}*${item.quantity}${
        item.isStore ? "(store)" : ""
      }; `;
    });
  });

  const handleTableCopy = () => {
    let copyText = document.getElementById("posCopyTable");
    if (document.createRange && window.getSelection) {
      let range = document.createRange();
      let sel = window.getSelection();
      // unselect any element in the page
      sel.removeAllRanges();

      try {
        range.selectNodeContents(copyText);
        sel.addRange(range);
      } catch (e) {
        range.selectNode(copyText);
        sel.addRange(range);
      }

      navigator.clipboard.writeText(sel.toString());

      sel.removeAllRanges();
      toast.info("Copied to clipboard", {
        toastId: "copyToClipboard",
        icon: <ContentCopy />,
      });
    }
  };

  const theme = useTheme();

  const tdStyle = {
    border: `1px solid ${theme.palette.divider}`,
    textWrap: "nowrap",
    padding: "3px",
  };

  const UsedCheques = data?.cheques.filter((chq) => !chq.cancelled);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <Paper variant="outlined" sx={{ p: 1, overflowX: "auto" }}>
          <table
            id="posCopyTable"
            cellSpacing="0"
            style={{ borderCollapse: "collapse" }}
          >
            <tbody>
              <tr>
                <td style={tdStyle}>{posID}</td>
                <td style={tdStyle}>{data?.defaultSupplier.name}</td>
                <td style={tdStyle}>{data?.amount}</td>
                <td style={tdStyle}>
                  {data?.withholding
                    ? (data?.amount / config.VAT) * config.withHoldingTax
                    : 0}
                </td>
                <td style={tdStyle}>
                  {data?.withholding
                    ? data?.amount -
                      (data?.amount / config.VAT) * config.withHoldingTax
                    : data?.amount}
                </td>
                <td style={tdStyle}>{data?.currency.toLowerCase()}</td>
                <td style={tdStyle}>
                  {data?.cheques.length > 0
                    ? UsedCheques.map((chq) =>
                        padWithLeadingZeros(chq.number)
                      ).join("; ")
                    : "-"}
                </td>
                <td style={tdStyle}>{clientList.toLowerCase()}</td>
                <td style={tdStyle}>{confirmationList.toLowerCase()}</td>
                <td style={tdStyle}>{itemsList}</td>
                <td style={tdStyle}>{data?.orderIDs.join("/")}</td>
                <td style={tdStyle}>
                  {`=HYPERLINK(CONCATENATE("https://portal.tec-today.com/Pos/edit?pos=","${posID}"),"POS")`}
                </td>
                <td style={tdStyle}>
                  {`=HYPERLINK(CONCATENATE("https://portal.tec-today.com/adminOrderManager/overview?i=","${data?.orderIDs.join(
                    "/"
                  )}"),"Order")`}
                </td>
              </tr>
            </tbody>
          </table>
        </Paper>
        <Stack alignItems={"flex-end"} sx={{ mt: 1 }}>
          <Button
            ref={(el) => {
              if (el && autoCopy) {
                el.click();
                handleClose();
              }
            }}
            startIcon={<CopyAll />}
            onClick={handleTableCopy}
            color="info"
            variant="contained"
          >
            Copy to clipboard
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}

const padWithLeadingZeros = (num) => {
  return String(num).padStart(6, "0");
};
