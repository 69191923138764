import React from "react";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Stack,
  Typography,
  Collapse,
} from "@mui/material";

import {
  ArrowDropDown,
  ArrowDropUp,
  CheckCircle,
  Close,
  CopyAll,
  Unpublished,
  Update,
} from "@mui/icons-material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import ChequeArea from "../common/ChequeArea";
import TransactionSummary from "../common/TransactionSummary";
import ReferencePoTable from "../tables/ReferencePoTable";
import { toast } from "react-toastify";
import { OrderSummary, OrderCard } from "../common/OrderSummary";
import { getItemReference, updateCheque } from "../../API/Cheques&Pos";
import { getExchangeRate } from "../../API/Sales";
import { LoadingButton } from "@mui/lab";
import NumberFormater from "../../../Components/common/NumberFormater";
import AreYouSure from "../../../Components/common/AreYouSure";
import NumberFormat from "../../../Components/common/NumberFormater";
import config from "../../Config";

export default function EditPosCheques({
  data,
  setData,
  loading,
  setEdited,
  copyClose,
}) {
  const [open, setOpen] = React.useState(false);
  const [cheques, setCheques] = React.useState(
    data?.cheques?.map((cheque) => ({ ...cheque }))
  );
  const [openPopUp, setOpenPopUp] = React.useState(false);
  const [changed, setChanged] = React.useState(false);

  const [exchangeRates, setExchangeRates] = React.useState({
    USD: { rate: 1 },
    KES: { rate: 1 },
  });
  const [updating, setUpdating] = React.useState(false);
  const [invalid, setInvalid] = React.useState(false);

  //get exchange rate
  React.useEffect(() => {
    getExchangeRate().then((res) => {
      setExchangeRates({ USD: res[0], KES: res[2] });
    });
  }, []);

  // check if cheque is valid

  React.useEffect(() => {
    const validateCheques = () => {
      let valid = true;
      const uniqueNumbers = [];

      if (cheques.length !== 0) {
        cheques.forEach((cheque) => {
          valid =
            valid &&
            cheque.number !== "" &&
            cheque.amount !== "" &&
            cheque.valid &&
            !uniqueNumbers.includes(cheque.number);
          uniqueNumbers.push(cheque.number);
        });
      }

      return valid;
    };

    const checkChange = () => {
      let valid = false;
      if (cheques.length === 0) {
        return false;
      }
      if (cheques.length !== data?.cheques.length) {
        return true;
      }
      cheques.forEach((cheque, index) => {
        valid =
          valid ||
          cheque.cancelled !== data?.cheques[index]?.cancelled ||
          cheque.number !== data?.cheques[index]?.number ||
          Number(cheque.amount) !== Number(data?.cheques[index]?.amount) ||
          cheque.bank !== data?.cheques[index]?.bank;
      });
      return valid;
    };
    const changed = checkChange();
    setChanged(changed);

    if (validateCheques() && cheques.length !== 0 && changed) {
      setInvalid(false);
      return;
    }
    setInvalid(true);
  }, [cheques, data.cheques]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCheque = () => {
    const Data = {
      posID: data?.poNumber,
      cheques: cheques.filter((cheque) => cheque.cancelled !== true),
      currency: data?.currency,
      supplier: data?.supplier,
    };

    setUpdating(true);
    updateCheque(Data)
      .then((res) => {
        setUpdating(false);
        toast.success(res.message);
        setCheques(res.cheques);
        setData((previous) => {
          previous.cheques = res.cheques.map((cheque) => ({ ...cheque }));
          return { ...previous };
        });
        setEdited(true);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to update cheques");
        setUpdating(false);
      });
  };

  const chequeAmount = data?.withholding
    ? data?.amount - (data?.amount / config.VAT) * config.withHoldingTax
    : data?.amount;

  const poCalcAmount = React.useMemo(() => {
    let total = 0;
    data?.poItems.forEach((order) => {
      order.items.forEach((item) => {
        if (!item.isStore) total += item.price * item.quantity;
      });
    });

    if (data?.withholding) return total * config.VAT;
    return total;
  }, [data]);

  const poCalcToCheque = React.useMemo(() => {
    if (data?.withholding)
      return poCalcAmount - (poCalcAmount / config.VAT) * config.withHoldingTax;
    return poCalcAmount * config.VAT;
  }, [poCalcAmount, data]);

  return (
    <>
      <Button
        disabled={loading}
        onClick={handleClickOpen}
        color="info"
        variant="contained"
      >
        Manage Cheques
      </Button>

      <AreYouSure
        open={openPopUp}
        onDecline={() => setOpenPopUp(false)}
        onAccept={() => {
          handleClose();
          setOpenPopUp(false);
        }}
        text={"Any changes made will be lost"}
      />

      <Dialog open={open} onClose={handleClose} fullScreen>
        <DialogContent>
          <Stack spacing={1}>
            <Typography variant="h4">Main details</Typography>
            <OrderLine name="Date Created" text={data?.date} />
            <OrderLine name="Purchase Order Number" text={data?.poNumber} />
            <OrderLine name=" Supplier" text={data?.defaultSupplier?.name} />
            <OrderLine name="Currency" text={data?.currency} />
            <OrderLine
              name="Amount"
              text={NumberFormat(data?.amount.toFixed(2))}
            />
            <OrderLine
              name="Withholding"
              text={<ChipStatus value={data?.withholding} />}
            />
            <OrderLine name="Note" text={data?.note} />

            <Typography variant="h4">Orders</Typography>
            {(data?.order_list || []).map((order) => (
              <OrderArea
                key={order.orderID}
                order={order}
                poItems={data?.poItems}
                orderItems={
                  data?.originalItems.find(
                    (object) => object.orderID === order.orderID
                  ).items
                }
                currency={data?.currency}
                setExchangeRates={setExchangeRates}
                exchangeRates={exchangeRates}
              />
            ))}

            <Paper sx={{ p: 2 }} variant="outlined">
              <Typography variant="h6">PO calculated Amount</Typography>
              <Stack
                direction="column"
                spacing={2}
                alignItems="flex-start"
                sx={{ pl: 2 }}
              >
                <Chip
                  label={`Item Costs: ${NumberFormater(
                    poCalcAmount.toFixed(2)
                  )}${data?.withholding ? " (VAT Included)" : ""}`}
                  color={"info"}
                />
                <Chip
                  label={`Cheque Amount: ${NumberFormater(
                    poCalcToCheque.toFixed(2)
                  )}${
                    poCalcToCheque.toFixed(2) === chequeAmount.toFixed(2)
                      ? ""
                      : " ≠ " + NumberFormater(chequeAmount.toFixed(2))
                  }`}
                  color={
                    poCalcToCheque.toFixed(2) === chequeAmount.toFixed(2)
                      ? "success"
                      : "error"
                  }
                  icon={
                    poCalcToCheque.toFixed(2) === chequeAmount.toFixed(2) ? (
                      <CheckCircle />
                    ) : (
                      <Unpublished />
                    )
                  }
                />
              </Stack>
            </Paper>

            <Typography variant="h4">Cheques</Typography>
            <ChequeArea
              cheques={cheques}
              setCheques={setCheques}
              amount={chequeAmount}
              edit={true}
              currency={data?.currency}
              isCashCheque={
                data?.defaultSupplier?.name === "cash" ||
                data?.defaultSupplier?.name === "Cash"
              }
              poNumber={data?.poNumber}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack direction={"column"} spacing={1}>
            <LoadingButton
              loading={updating}
              onClick={handleCheque}
              variant="contained"
              disabled={invalid}
              startIcon={<Update />}
            >
              Update Cheque
            </LoadingButton>
            <Stack direction="row" spacing={1}>
              <Button
                variant="outlined"
                color="info"
                startIcon={<CopyAll />}
                onClick={() => {
                  copyClose();
                  if (changed) {
                    setOpenPopUp(true);

                    return;
                  }
                  handleClose();
                }}
              >
                Copy & close
              </Button>
              <Button
                variant="outlined"
                color="warning"
                startIcon={<Close />}
                onClick={() => {
                  if (changed) {
                    setOpenPopUp(true);
                    return;
                  }
                  handleClose();
                }}
              >
                Close
              </Button>
            </Stack>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
}

const OrderLine = ({ name, text }) => {
  return (
    <>
      <Grid container item xs={12}>
        <Grid item xs>
          <strong>{name}</strong>
        </Grid>
        <Grid item xs>
          {text}
        </Grid>
      </Grid>
      <Divider />
    </>
  );
};

const ChipStatus = ({ value }) => {
  return (
    <Chip
      label={value ? "TRUE" : "FALSE"}
      color={value ? "success" : "warning"}
    />
  );
};

function ItemComparison({
  po_item,
  originalItems,
  orderID,
  posItems,
  exchangeRates,
  setExchangeRates,
  currency,
  poCurrency,
}) {
  const order_item = originalItems.find(
    (item) => item.itemID === po_item.itemID
  );
  const [referenceData, setReferenceData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [showReference, setShowReference] = React.useState(false);
  const [showTransaction, setShowTransactions] = React.useState(false);

  const getPosItems = () => {
    const items = posItems.find((object) => object.orderID === orderID).items;
    const filtered = items.filter((item) => item.itemID === po_item.itemID);

    return filtered;
  };

  React.useEffect(() => {
    if (orderID.includes("MANUAL")) {
      setLoading(false);
      return;
    }

    if (loading) {
      getItemReference({ itemID: po_item.itemID }).then((res) => {
        setLoading(false);
        setReferenceData(res);
      });
    }
  }, [loading, orderID, po_item.itemID]);
  const fields = [
    { name: "Part Number", property: "partNumber" },
    { name: "Description", property: "description" },
    { name: "Quantity ", property: "quantity" },
    { name: "Price", property: "price" },
  ];

  return (
    <>
      <TableContainer component={Paper} sx={{ mb: 2, mt: 2 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "var(--default)" }}>
              <TableCell></TableCell>
              <TableCell align="left">Order Item</TableCell>
              <TableCell align="left">
                POS Item
                {po_item?.isStore && (
                  <Chip label="store" color="info" sx={{ ml: 1 }} />
                )}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((row) => (
              <TableRow key={row.name}>
                <TableCell sx={{ width: "150px" }}>{row.name}</TableCell>
                <TableCell align="left">
                  {row.property === "price"
                    ? `${currency} ${NumberFormater(
                        order_item[row.property].toFixed(2)
                      )}`
                    : order_item[row.property]}
                </TableCell>
                <TableCell align="left">
                  {row.property === "price"
                    ? `${poCurrency} ${NumberFormater(
                        po_item[row.property].toFixed(2)
                      )}`
                    : po_item[row.property]}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell
                sx={{ width: "150px", color: "var(--secondarycolor)" }}
              >
                More Info
              </TableCell>
              <TableCell align="left">
                <Button
                  variant="outlined"
                  onClick={() => setShowReference((previous) => !previous)}
                  sx={{ mt: 0 }}
                  endIcon={showReference ? <ArrowDropUp /> : <ArrowDropDown />}
                >
                  Reference POS
                </Button>
              </TableCell>
              <TableCell align="left">
                <Button
                  variant="outlined"
                  onClick={() => setShowTransactions((previous) => !previous)}
                  sx={{ mt: 0 }}
                  endIcon={
                    showTransaction ? <ArrowDropUp /> : <ArrowDropDown />
                  }
                >
                  TransactionSummary
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Collapse in={showReference}>
        <ReferencePoTable
          data={referenceData}
          loading={loading}
          setLoading={setLoading}
        />
      </Collapse>
      <Collapse in={showTransaction}>
        <TransactionSummary
          items={[...getPosItems(), ...order_item.poItems]}
          sellingPrice={order_item.price * order_item.quantity}
          currency={currency}
          poCurrency={poCurrency}
          setExchangeRates={setExchangeRates}
          exchangeRates={exchangeRates}
          unitPrice={order_item.price}
          poPrice={po_item.price}
          quantity={po_item.quantity}
          vat={order_item.vat}
        />
      </Collapse>
    </>
  );
}

function OrderArea({
  order,
  poItems,
  orderItems,
  currency,
  setExchangeRates,
  exchangeRates,
}) {
  const [hide, setHide] = React.useState(true);
  return (
    <Paper sx={{ mb: 3 }}>
      <Stack spacing={3}>
        <Paper sx={{ p: 3 }}>
          <Stack spacing={1}>
            <OrderSummary
              order={order}
              poItems={poItems}
              orderItems={orderItems}
              currency={currency}
              setExchangeRates={setExchangeRates}
              exchangeRates={exchangeRates}
            />
            <OrderCard details={order} />
            <Collapse in={hide}>
              {poItems
                .find((object) => object.orderID === order.orderID)
                .items.map((item) => (
                  <ItemComparison
                    key={item.id}
                    originalItems={orderItems}
                    po_item={item}
                    orderID={order.orderID}
                    posItems={poItems}
                    setExchangeRates={setExchangeRates}
                    exchangeRates={exchangeRates}
                    currency={order.currency}
                    poCurrency={currency}
                  />
                ))}
            </Collapse>
            <>
              <Divider />
              <Button
                onClick={() => setHide((previous) => !previous)}
                startIcon={hide ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                variant={hide ? "text" : "outlined"}
                sx={{ m: 0 }}
                fullWidth
              >
                {hide ? "Hide Items" : "Show Items"}
              </Button>
            </>
          </Stack>
        </Paper>
      </Stack>
    </Paper>
  );
}
