import React from "react";
import {
  Button,
  Grid,
  Stack,
  Typography,
  Collapse,
  Tooltip,
  CardActionArea,
  IconButton,
  Box,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  Chip,
  Alert,
  AlertTitle,
} from "@mui/material";

import NumberFormater from "../../../Components/common/NumberFormater";
import AreYouSure from "../../../Components/common/AreYouSure";
import {
  ArrowDropDown,
  ArrowDropUp,
  Delete,
  FindInPage,
  Info,
  Summarize,
  Update,
  WarningAmber,
} from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import NumberFormat from "../../../Components/common/NumberFormater";
import config from "../../Config";

import Paper from "@mui/material/Paper";

import TransactionSummary from "../common/TransactionSummary";
import ReferencePoTable from "../tables/ReferencePoTable";

import { approveOrder, getReference } from "../../API/Cheques&Pos";
import UniversalTable from "../../../Components/common/UniversalTable";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import AddConfirmation from "./AddConfirmation";

export function OrderSummary({
  order,
  poItems,
  currency,
  setExchangeRates,
  exchangeRates,
  orderDelete,
}) {
  const [showReference, setShowReference] = React.useState(false);
  const [referenceData, setReferenceData] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [showTransaction, setShowTransaction] = React.useState(false);

  React.useEffect(() => {
    if (loading) {
      getReference({ order: order.orderID }).then((res) => {
        setLoading(false);
        setReferenceData(res);
      });
    }
  }, [loading, referenceData, order]);

  const budgetSpent = React.useMemo(() => {
    let spent = 0;
    order.items.forEach((item) => {
      item.poItems.forEach((pitem) => {
        const rate =
          exchangeRates[order.currency].rate /
          exchangeRates[pitem.currency].rate;
        spent += pitem.price * pitem.quantity * rate;
      });
    });
    return spent;
  }, [exchangeRates, order.currency, order.items]);

  // the amount of the current pos items added for this order item
  const addedItemsAmount = React.useMemo(() => {
    let amount = 0;
    const posObject = poItems.find(
      (posObject) => posObject.orderID === order.orderID
    );

    posObject.items.forEach((item) => {
      const rate =
        exchangeRates[order.currency].rate / exchangeRates[currency].rate;
      amount += Number(item.quantity) * Number(item.price) * rate;
    });

    return amount;
  }, [currency, exchangeRates, order.currency, order.orderID, poItems]);

  const lineItemsAquired = React.useMemo(() => {
    let completed = 0;
    // count the current and previous poItems that are main items
    // check if its previous
    const purchasingItems = poItems.find(
      (posObject) => posObject.orderID === order.orderID
    ).items;

    order.items.forEach((orderItem) => {
      // main item for current pos
      const mainItemQuantity =
        purchasingItems.find(
          (item) => item.itemID === orderItem.id && !item.isPending
        )?.quantity || 0;
      // related main items from other pos
      const otherMainItemsQuantity = orderItem.poItems.reduce(
        (accumulator, item) => {
          const isMain = item.pendingID === null;
          if (isMain) return item.quantity + accumulator;
          return accumulator + 0;
        },
        0
      );

      // check if there are any pending items
      const noPending = orderItem.pending.length === 0;
      // determine if this line item is completed
      completed +=
        mainItemQuantity + otherMainItemsQuantity >= orderItem.quantity &&
        noPending
          ? 1
          : 0;
    });
    return completed;
  }, [order.items, order.orderID, poItems]);

  const amount = order.amount;
  const totalSpent = React.useMemo(() => {
    if (!order?.vat) return (budgetSpent + addedItemsAmount) * config.VAT;
    return budgetSpent + addedItemsAmount;
  }, [budgetSpent, addedItemsAmount, order]);

  const checkCompleted = lineItemsAquired >= order.items.length;

  const getPoItems = () => {
    const items = poItems.find(
      (object) => object.orderID === order.orderID
    ).items;
    return items;
  };

  const getPoItemsDetails = () => {
    let items = [];
    const poItems = order.items.map((item) => item.poItems);
    poItems.forEach((itemList) => {
      items = [...items, ...itemList];
    });
    return items;
  };

  const [orderApproved, setOrderApproved] = React.useState(false);

  React.useEffect(() => {
    if (order.orderID.includes("MANUAL")) {
      setOrderApproved(true);
      return;
    }
    setOrderApproved(
      order.status.some((status) => status.status === "approved")
    );
  }, [order.status, order.orderID]);

  const [showApproved, setShowApproved] = React.useState(false);

  const handleApprove = () => {
    setShowApproved(false);
    toast.promise(
      new Promise((resolve, reject) => {
        approveOrder(order.orderID, lineItemsAquired !== order.items.length)
          .then((res) => {
            setOrderApproved(true);
            resolve(res);
          })
          .catch((err) => {
            reject(err);
          });
      }),
      {
        pending: "Approving order...",
        success: {
          render: () => {
            return "Order approved!";
          },
        },
        error: {
          render: () => {
            return "Failed to approve order";
          },
        },
      }
    );
  };

  return (
    <>
      <Box
        sx={{
          mb: 3,
          bgcolor: (theme) => theme.palette.info.main,
          borderRadius: 3,
        }}
      >
        <Stack spacing={3}>
          <Paper
            sx={{
              p: 3,
              bgcolor: (theme) =>
                theme.palette.mode === "dark"
                  ? "rgba(0,0,0,0.7)"
                  : "rgba(255,255,255,0.7)",
              borderRadius: 3,
              border: 1,
            }}
          >
            <Stack spacing={1}>
              <Stack direction="row" spacing={1} justifyContent="space-between">
                <Stack
                  spacing={1}
                  direction={{ xs: "column", md: "row" }}
                  alignItems="center"
                >
                  <Typography variant="h5" align="center">
                    SUMMARY : {order.isManual ? "MANUAL ORDER" : order.orderID}
                  </Typography>
                  {(loading === true || referenceData.length !== 0) && (
                    <Button
                      variant="outlined"
                      color="info"
                      onClick={() => setShowReference((previous) => !previous)}
                      sx={{ mt: 0 }}
                      startIcon={<FindInPage />}
                      endIcon={
                        showReference ? <ArrowDropUp /> : <ArrowDropDown />
                      }
                    >
                      Reference POS
                    </Button>
                  )}
                  {!order.isManual && !order.orderID.startsWith("MANUAL") && (
                    <Button
                      color="info"
                      variant="outlined"
                      component={Link}
                      target="_blank"
                      to={`/adminOrderManager/overview?i=${order.orderID}`}
                      startIcon={<Summarize />}
                    >
                      Overview
                    </Button>
                  )}
                  {!order.isManual && !order.orderID.startsWith("MANUAL") && (
                    <Button
                      color="info"
                      variant="outlined"
                      component={Link}
                      target="_blank"
                      to={`/adminOrderManager/manage_status?i=${order.orderID}`}
                      startIcon={<Update />}
                    >
                      Status
                    </Button>
                  )}
                </Stack>
                {orderDelete && (
                  <Tooltip title="Remove Order">
                    <IconButton
                      onClick={() => orderDelete(order.orderID)}
                      color="error"
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>

              <Collapse in={showReference}>
                <ReferencePoTable
                  data={referenceData}
                  loading={loading}
                  setLoading={setLoading}
                />
              </Collapse>

              <Grid container spacing={1} sx={{ ml: 1.3 }}>
                <Grid item xs={12} md={3}>
                  <Typography varaint="h6" sx={{ fontWeight: "bold" }}>
                    Total amount ({order.currency})
                  </Typography>
                  <Typography varaint="caption" sx={{ mt: 1 }}>
                    {`${NumberFormater(order.amount.toFixed(2))} `} EXC
                  </Typography>
                  {order.vat && (
                    <Typography varaint="caption" sx={{ mt: 1 }}>
                      {`${NumberFormater(
                        (order.amount * config.VAT).toFixed(2)
                      )} `}{" "}
                      INC
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} md={2}>
                  <CardActionArea
                    onClick={() => setShowTransaction((previous) => !previous)}
                  >
                    <Typography varaint="h6" sx={{ fontWeight: "bold" }}>
                      Total Profit{" "}
                      <Tooltip
                        title={`${
                          showTransaction ? "Hide" : "Show"
                        } Transaction History`}
                      >
                        <Info sx={{ fontSize: "1rem" }} />
                      </Tooltip>
                    </Typography>
                    <Typography
                      varaint="caption"
                      sx={{
                        mt: 1,
                        color: (theme) =>
                          totalSpent < amount
                            ? theme.palette.success.main
                            : theme.palette.error.main,
                      }}
                    >
                      {`${order.currency}  ${NumberFormater(
                        (amount - totalSpent).toFixed(2)
                      )}`}
                    </Typography>
                    <Typography
                      varaint="caption"
                      sx={{
                        mt: 1,
                        color: (theme) =>
                          totalSpent < amount
                            ? theme.palette.success.main
                            : theme.palette.error.main,
                      }}
                    >
                      {`${NumberFormater(
                        (((amount - totalSpent) / amount) * 100).toFixed(2)
                      )}%`}
                    </Typography>
                  </CardActionArea>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Typography varaint="h6" sx={{ fontWeight: "bold" }}>
                    Line Items
                  </Typography>
                  <Typography varaint="caption" sx={{ mt: 1 }}>
                    {`${lineItemsAquired} / ${order.items.length}`}{" "}
                    {lineItemsAquired !== order.items.length && (
                      <WarningAmber color="warning" fontSize="small" />
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Typography varaint="h6" sx={{ fontWeight: "bold" }}>
                    Status
                  </Typography>
                  <Typography
                    varaint="caption"
                    sx={{
                      mt: 1,
                      color: (theme) =>
                        checkCompleted
                          ? theme.palette.success.main
                          : theme.palette.text.primary,
                    }}
                  >
                    {checkCompleted ? "Completed" : "Incomplete"}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Typography varaint="h6" sx={{ fontWeight: "bold" }}>
                    Company
                  </Typography>
                  <Typography varaint="caption" sx={{ mt: 1 }}>
                    {order.company_name}
                  </Typography>
                </Grid>
              </Grid>
              <Collapse in={showTransaction}>
                <TransactionSummary
                  items={[...getPoItemsDetails(), ...getPoItems()]}
                  sellingPrice={order.amount}
                  currency={order.currency}
                  poCurrency={currency}
                  setExchangeRates={setExchangeRates}
                  exchangeRates={exchangeRates}
                  vat={order.vat}
                />
              </Collapse>
            </Stack>
            <Collapse in={!order?.vat}>
              <Alert severity="error">
                <AlertTitle>Client does NOT PAY VAT</AlertTitle>
              </Alert>
            </Collapse>
            <Divider sx={{ my: 1 }} />
            <Typography variant="h6" gutterBottom>
              Confirmation
            </Typography>
            <Box sx={{ ml: 2 }}>
              <Stack
                direction={{ xs: "column", md: "row" }}
                spacing={1}
                justifyContent="space-between"
                alignItems={{ xs: "flex-start", md: "flex-end" }}
              >
                <Box>
                  <Typography>
                    Type: {order?.confirmation?.type || "none"}
                  </Typography>
                  <Typography gutterBottom>
                    Details:{" "}
                    {(order?.confirmation?.details || "none").toUpperCase()}
                  </Typography>
                </Box>
                <AddConfirmation
                  orderID={order.orderID}
                  onChangesSaved={() => toast.success("Confirmation added")}
                  reloadWarning
                />
              </Stack>
            </Box>
          </Paper>
        </Stack>
      </Box>
      <Collapse in={!orderApproved}>
        <Alert
          severity="warning"
          action={
            <Button
              variant="outlined"
              color="warning"
              onClick={() => setShowApproved(true)}
              sx={{ my: "auto" }}
            >
              Approve
            </Button>
          }
        >
          <AlertTitle>Order not approved</AlertTitle>
          This order has not yet been approved
        </Alert>
        <AreYouSure
          open={showApproved}
          text={
            "Are you sure you want to approve this order? This will use the items from PO's for the order status"
          }
          acceptText="Approve"
          declineText="Cancel"
          onAccept={handleApprove}
          onDecline={() => setShowApproved(false)}
        />
      </Collapse>
    </>
  );
}

function OrderCardItem({
  item,
  checkAdded,
  handleSingleItem,
  index,
  setItemIndex,
  setShowPending,
}) {
  const otherMainItemsQuantity = React.useMemo(
    () =>
      item.poItems.reduce((accumulator, item) => {
        const isMain = item.pendingID === null;
        if (isMain) return item.quantity + accumulator;
        return accumulator + 0;
      }, 0),
    [item]
  );

  return (
    <TableRow>
      {checkAdded && (
        <TableCell>
          <Button
            disabled={checkAdded(item.id)}
            variant="contained"
            color="success"
            onClick={() => handleSingleItem(index)}
            startIcon={<AddIcon />}
          >
            Add Item
          </Button>
        </TableCell>
      )}
      <TableCell>
        <Chip
          color={
            otherMainItemsQuantity === item.quantity
              ? "success"
              : otherMainItemsQuantity > item.quantity
              ? "error"
              : "warning"
          }
          label={`${otherMainItemsQuantity}/${item.quantity}`}
        />
      </TableCell>
      <TableCell>{item.partNumber}</TableCell>
      <TableCell>{item.description}</TableCell>
      <TableCell>{item.quantity}</TableCell>
      <TableCell>{NumberFormat(item.price.toFixed(2))}</TableCell>
      <TableCell>
        {item.pending.length !== 0 ? (
          <CardActionArea
            sx={{ display: "flex", justifyContent: "center" }}
            onClick={() => {
              setShowPending(true);
              setItemIndex(index);
            }}
          >
            <Typography
              display="inline"
              color={"primary"}
              textAlign="center"
              sx={{ fontStyle: "italic" }}
            >
              Pending items
            </Typography>
          </CardActionArea>
        ) : (
          <></>
        )}
      </TableCell>
    </TableRow>
  );
}

export function OrderCard({ details, addItem, checkAdded }) {
  const [open, setOpen] = React.useState(false);
  const [itemIndex, setItemIndex] = React.useState(0);
  const [showPending, setShowPending] = React.useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const handleSingleItem = (index) => {
    addItem(details.orderID, { ...details.items[index] });
  };

  const addAllItems = (orderID, items) => {
    items.forEach((item) => {
      addItem(orderID, item);
    });
  };
  const headers = [
    {
      id: "description",
      label: "Item Description",
    },
    {
      id: "quantity",
      label: "Quantity",
    },
    {
      id: "date",
      label: "Date Created",
    },
  ];

  return (
    <Paper variant="outlined">
      <Collapse in={open}>
        <Paper elevation={0}>
          <Box p={2}>
            <Typography variant="h6">Details</Typography>
            <Typography>Company: {details.company_name}</Typography>
            <Typography>Date Created: {details.date_created}</Typography>
            <Typography gutterBottom>Total: {details.total}</Typography>
            <Divider />
            <Typography variant="h6">Confirmation</Typography>
            <Typography>
              Type: {details?.confirmation?.type || "none"}
            </Typography>
            <Typography>
              Details:{" "}
              {(details?.confirmation?.details || "none").toUpperCase()}
            </Typography>
          </Box>
          <Divider sx={{ my: 2 }} />
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {checkAdded && <TableCell></TableCell>}
                  <TableCell />
                  <TableCell>Part Number</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Unit Price</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {details.items.map((item, index) => (
                  <OrderCardItem
                    key={index}
                    item={item}
                    index={index}
                    handleSingleItem={handleSingleItem}
                    setItemIndex={setItemIndex}
                    setShowPending={setShowPending}
                    checkAdded={checkAdded}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        <Dialog
          maxWidth={"lg"}
          open={showPending}
          onClose={() => setShowPending(false)}
        >
          <UniversalTable
            loading={false}
            setLoading={() => false}
            data={details.items[itemIndex].pending}
            headers={headers}
            name={`Pending items for ${details.items[itemIndex].description}`}
          />
        </Dialog>
      </Collapse>
      <Stack
        spacing={2}
        direction={{ xs: "column", md: "row" }}
        alignItems="center"
        sx={{ p: 2 }}
      >
        <Button
          onClick={toggleOpen}
          endIcon={open ? <ArrowDropUp /> : <ArrowDropDown />}
        >
          {open ? "Hide Details" : "Show Details"}
        </Button>
        {checkAdded && (
          <Button
            variant="contained"
            onClick={() => addAllItems(details.orderID, details.items)}
            startIcon={<AddIcon />}
          >
            Add All Items
          </Button>
        )}
      </Stack>
    </Paper>
  );
}
