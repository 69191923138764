import toTitleCase from "../../Components/common/toTitleCase";
import { axiosAuth } from "../../CustomAxios/Protected";
import DownloadFile from "../Components/common/FileDownload";

/* -------------------------------------------------------------------------- */
/*                                   General                                  */
/* -------------------------------------------------------------------------- */

export function clearReports() {
  return axiosAuth.get("/admin-sales/clearReports").then((res) => {
    return res.data;
  });
}

/* -------------------------------------------------------------------------- */
/*                                   Quotes                                   */
/* -------------------------------------------------------------------------- */
export function getQuotePDF(quote_no, companyName) {
  return axiosAuth
    .get(`/admin-sales/quotes/get_pdf/${encodeURIComponent(quote_no)}`, {
      responseType: "blob",
    })
    .then((response) => {
      const fileName = `${toTitleCase(companyName)} ${quote_no}`
        .replace(/[^a-z0-9]/gi, " ")
        .replace(/\s+/g, " ")
        .trim();
      DownloadFile(response, fileName);

      return {
        status: "success",
        message: "Quote PDF Downloaded Successfully",
      };
    });
}

export function getQuoteHtml(quote_no) {
  return axiosAuth
    .get(`/admin-sales/quotes/get_html/${encodeURIComponent(quote_no)}`)
    .then((response) => response);
}

export function getQuotePages(data) {
  return axiosAuth
    .get("/admin-sales/quotes/pages_count", {
      params: {
        page: data.currentPage,
        currency: data.currency,
        company: data.companyName,
        part: data.part,
        exempt: data.exempt,
        to: data.to,
        from: data.from,
      },
    })
    .then((res) => {
      return res.data;
    });
}

export function getAllQuotes(data, signal) {
  return axiosAuth
    .get(`/admin-sales/quotes/get_all`, {
      params: {
        page: data.currentPage,
        currency: data.currency,
        company: data.companyName,
        part: data.part,
        exempt: data.exempt,
        to: data.to,
        from: data.from,
      },
      signal,
    })
    .then((res) => {
      return res.data;
    });
}

export function getRecentQuotes(accountManagerId) {
  return axiosAuth
    .get("/admin-sales/quotes/get_recent", {
      params: { accountManagerId },
    })
    .then((res) => {
      return res.data;
    });
}

export function getQuoteByIDTable(id, accManagerID) {
  return axiosAuth
    .post("/admin-sales/quotes/get_tableview", {
      quoteNo: id,
      accManagerID: accManagerID,
    })
    .then((res) => {
      return res.data;
    });
}

/* -------------------------------------------------------------------------- */
/*                                  Companys                                  */
/* -------------------------------------------------------------------------- */
export function getCompanyPages() {
  return axiosAuth
    .get("/admin-sales/clients/get_all_companies_pages")
    .then((res) => {
      return res.data;
    });
}
export function getAllComapnies(data) {
  return axiosAuth
    .get("/admin-sales/clients/all_companies", {
      params: {
        page: data.currentPage,
      },
    })
    .then((res) => {
      return res.data;
    });
}

export function getCompanyByID(id) {
  return axiosAuth
    .get("/admin-sales/clients/get_company_by_id", {
      params: { companyID: id },
    })
    .then((res) => {
      return res.data;
    });
}

export function updateCompany(data) {
  return axiosAuth
    .post("/admin-sales/clients/update_company", {
      companyID: data.companyID,
      name: data.name,
      value: data.value,
    })
    .then((res) => {
      return res.data;
    });
}

export function deleteCompany(id) {
  return axiosAuth
    .get("/admin-sales/clients/delete_company", {
      params: { companyID: id },
    })
    .then((res) => {
      return res.data;
    });
}

/* -------------------------------------------------------------------------- */
/*                                   Clients                                  */
/* -------------------------------------------------------------------------- */

export function getClientPages(data) {
  return axiosAuth
    .get("/admin-sales/clients/pages_count", {
      params: {
        page: data.currentPage,
        company: data.companyName,
        newClient: data.newClient,
        manager_email: data.managerEmail,
      },
    })
    .then((res) => {
      return res.data;
    });
}

export function getClients(data, signal) {
  return axiosAuth
    .get(`/admin-sales/clients/get_all`, {
      params: {
        page: data.currentPage,
        company: data.companyName,
        newClient: data.newClient,
        manager_email: data.managerEmail,
      },
      signal,
    })
    .then((res) => {
      return res.data;
    });
}

export function searchClientsAdmin(data) {
  return axiosAuth
    .get(`/admin-sales/clients/search`, {
      params: {
        searchKey: data.searchKey,
      },
    })
    .then((res) => {
      return res.data;
    });
}

export function getClientByID(id) {
  return axiosAuth
    .post(`/admin-sales/clients/get_by_id`, { clientID: id })
    .then((res) => {
      return res.data;
    });
}

export function getAllAccountManagers(data) {
  return axiosAuth
    .get("/admin-sales/clients/get_all_account_managers", {
      params: { searchKey: data.searchKey },
    })
    .then((res) => {
      return res.data;
    });
}

export function getAllCompaniesClients(data) {
  return axiosAuth
    .get("/admin-sales/clients/get_all_companies", {
      params: { searchKey: data.searchKey },
    })
    .then((res) => {
      return res.data;
    });
}

export function getAllCompaniesQuotes(data) {
  return axiosAuth
    .get("/admin-sales/quotes/get_all_companies", {
      params: { searchKey: data.searchKey },
    })
    .then((res) => {
      return res.data;
    });
}

export function getClientReport(name, options) {
  return axiosAuth
    .get(`/admin-sales/clients/get_report`, {
      params: {
        options,
      },
      responseType: "blob",
    })
    .then((response) => {
      const fileName = `${name}.xlsx`;
      DownloadFile(response, fileName);

      return {
        status: "success",
        message: "Report Downloaded Successfully",
      };
    });
}

export function getCompanyReport(name, options) {
  return axiosAuth
    .get(`/admin-sales/clients/company/get_report`, {
      params: {
        options,
      },
      responseType: "blob",
    })
    .then((response) => {
      const fileName = `${name}.xlsx`;
      DownloadFile(response, fileName);

      return {
        status: "success",
        message: "Report Downloaded Successfully",
      };
    });
}

export function deleteClient(id) {
  return axiosAuth
    .get("/admin-sales/clients/delete", { params: { clientID: id } })
    .then((res) => {
      return res.data;
    });
}

export function updateClientVerification(data) {
  return axiosAuth
    .post("/admin-sales/clients/update_verification", data)
    .then((res) => {
      return res.data;
    });
}

/* -------------------------------------------------------------------------- */
/*                              Transfer Requests                             */
/* -------------------------------------------------------------------------- */

export function getTranferRequestCount() {
  return axiosAuth.get("/admin-sales/transfer/get_count").then((res) => {
    return res.data;
  });
}

export function getAllTransgerRequests() {
  return axiosAuth.get("/admin-sales/transfer/get_all").then((res) => {
    return res.data;
  });
}

export function handleTransfer(data) {
  return axiosAuth
    .post("/admin-sales/transfer/handle_request", data)
    .then((res) => {
      return res.data;
    });
}

/* -------------------------------------------------------------------------- */
/*                                    RFQS                                    */
/* -------------------------------------------------------------------------- */

export function getAdminRfqPages(data, signal) {
  return axiosAuth
    .get("/admin-sales/rfqs/pages_count", {
      params: {
        page: data.currentPage,
      },
      signal,
    })
    .then((res) => {
      return res.data;
    });
}

export function getAdminRfqs() {
  return axiosAuth.get("/admin-sales/rfqs/get_all").then((res) => {
    return res.data;
  });
}

/* -------------------------------------------------------------------------- */
/*                                   Reports                                  */
/* -------------------------------------------------------------------------- */
export function getAccountManagerAccess() {
  return axiosAuth
    .get("/admin-sales/reports/account_manager_access")
    .then((res) => {
      return res.data;
    });
}

export function getItemReportAccess() {
  return axiosAuth
    .get("/admin-sales/reports/item_report_access")
    .then((res) => {
      return res.data;
    });
}

export function getAdminDailySummary(accManagerID, date) {
  return axiosAuth
    .get(`/admin-sales/get_daily_summary/${accManagerID}`, {
      params: { date: date },
    })
    .then((res) => {
      return res.data;
    });
}

// Get weekly summary
export function getAdminWeeklySummary(accManagerID, startDate, endDate) {
  return axiosAuth
    .get(`/admin-sales/get_weekly_summary/${accManagerID}`, {
      params: { startDate: startDate, endDate: endDate },
    })
    .then((res) => {
      return res.data;
    });
}

// Download Summary
export function downloadSummary(accManagerID, summary_type, name) {
  return axiosAuth
    .get(`/admin-sales/download_summary/${accManagerID}/${summary_type}`, {
      responseType: "blob",
    })
    .then((response) => {
      const fileName = `${name}.xlsx`;
      DownloadFile(response, fileName);

      return {
        status: "success",
        message: "Report Downloaded Successfully",
      };
    });
}

// Item Report
export function getItemReport(searchTerm) {
  return axiosAuth
    .post("/admin-sales/item_report", { searchTerm })
    .then((res) => {
      return res.data;
    });
}

//Account manager peroformance
export function getAccountManagerPerformance({
  accManagerID,
  startDate,
  endDate,
}) {
  return axiosAuth
    .get(`/admin-sales/account_manager_performance/${accManagerID}`, {
      params: { startDate: startDate, endDate: endDate },
    })
    .then((res) => {
      return res.data;
    });
}
