import React from "react";
import {
  Paper,
  Typography,
  Button,
  Divider,
  Tooltip,
  Box,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { Outlet, Link, useMatch, useResolvedPath } from "react-router-dom";
import { SessionManager } from "react-session.manager.sk";

import PersonIcon from "@mui/icons-material/Person";
import SecurityIcon from "@mui/icons-material/Security";
import { Copyright } from "../../App";
import { toast } from "react-toastify";

function FancyButton(props) {
  let resolved = useResolvedPath(props.url);
  const selected = useMatch({ path: resolved.pathname, end: true });

  return (
    <Button
      component={Link}
      to={props.url}
      endIcon={<props.icon />}
      variant={selected ? "contained" : "outlined"}
      color={selected ? "primary" : "secondary"}
    >
      {props.name}
    </Button>
  );
}

export default function Main(props) {
  const { setRefreshData } = React.useContext(SessionManager);

  React.useEffect(() => {
    setRefreshData(true);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Typography variant="h4" color="primary">
        Settings
      </Typography>
      <Paper variant="outlined" sx={{ my: 2, p: 2 }}>
        <Typography variant="body1" sx={{ mb: 2 }}>
          You can manage your account settings from here
        </Typography>
        <Stack
          direction={{ xs: "column", md: "row" }}
          spacing={2}
          mb={2}
          divider={<Divider orientation="vertical" flexItem />}
        >
          <FancyButton
            name="Profile"
            url="/settings/profile"
            icon={PersonIcon}
          />
          <FancyButton
            name="Devices"
            url="/settings/devices"
            icon={SecurityIcon}
          />
        </Stack>
      </Paper>
      <Outlet />
      <FixLogin />
      <Copyright />
    </>
  );
}

function FixLogin() {
  const handleFix = () => {
    // check if Autorization is in session storage
    if (sessionStorage.getItem("Authorization")) {
      // move to local storage
      localStorage.setItem(
        "Authorization",
        sessionStorage.getItem("Authorization")
      );
      // remove from session storage
      sessionStorage.removeItem("Authorization");
      toast.success("Login Fixed");
      return;
    }
    toast.info("No issues found to fix");
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        mt: 2,
      }}
    >
      <Tooltip title="If you keep getting logged out on new tabs">
        <Button onClick={handleFix}>Fix Login</Button>
      </Tooltip>
    </Box>
  );
}
